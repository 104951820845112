<template>
	<div id="teamS" class="teamS2 acea-row row-between">
		<template v-if="pagetype == 'partners'">
			<div class="teamItem teamItem2 acea-row" v-for="item in list">
				<!-- <img :src="item.partners_img" alt="" class="pic"> -->
				<div class="cont">
					<div class="name">{{item.partners_name}}</div>
					<div class="label">
						<div v-for="i in item.label">{{i}}</div>
					</div>
					<div class="label">
						<div style="white-space: pre-line;">
							{{ item.content }}
						</div>
					</div>
					<!-- <div class="info" v-html="item.content"></div> -->
				</div>
			</div>
		</template>
		<template v-if="pagetype == 'team'">
			<div class="teamItem acea-row" v-for="item in list">
				<img :src="item.team_img" alt="" class="pic">
				<div class="cont">
					<div class="name">{{item.team_name}}</div>
					<div class="label">
						<p v-for="i in item.position_title">{{i}}</p>
					</div>
					<div class="info" v-html="item.content"></div>
				</div>
			</div>
		</template>
	</div>
</template>

<script>
	export default {
		props: {
			list: Array,
			pagetype: {
				type: String,
				default: 'team'
			}
		},
		data() {
			return {
				page: 1,
				pageSize: 10,
				total: 0
			}
		},
		mounted() {
			var to = this.$route.query.to
			if (to) {
				this.$el.querySelector(to)
			}
		},
		methods: {}
	}
</script>

<style lang="scss">
	$fontColor:#1D2A55;
	.label div{
		font-size: 18px;
		
	}
	#teamS {
		.teamItem {
			width: calc(50% - 20px);
			background: #FFF;
			box-shadow: 0px 5px 19px 1px rgba(29, 42, 85, 0.2);
			margin-bottom: 40px;

			.pic {
				width: 300px;
			}

			.cont {
				width: calc(100% - 300px);
				box-sizing: border-box;
				padding: 30px 24px;

				.name {
					font-size: 30px;
					color: rgba($color: $fontColor, $alpha: 1.0);
					font-weight: bold;
				}

				.label {
					margin-top: 30px;

					p {
						font-size: 22px;
						color: rgba($color: $fontColor, $alpha: 1.0);
						line-height: 36px;
					}
				}

				.info {
					border-top: 1px solid $fontColor;
					margin-top: 18px;
					padding-top: 18px;
					color: rgba($color: $fontColor, $alpha: 0.5);
					font-size: 16px;
					line-height: 28px;
				}
			}

			&:nth-child(2n) {
				margin-left: 40px;
			}
		}
	}
	.teamS2{
		columns: 3 30%;
		column-gap: 1%;
		.teamItem2{
			width: 30% !important;
			margin-left: 0 !important;
			min-height: 290px;
			.cont{
				width: 100% !important;
				p{
					
					margin: 20px 0;
				}
			}
		}
	}
	
	@media screen and(max-width:1200px) {
		.teamS2{
			columns: 2 48%;
			column-gap: 1%;
			.teamItem2{
				width: 48% !important;
				margin-left: 0 !important;
				min-height: 290px;
				.cont{
					width: 100% !important;
				}
			}
		}
	}

	
	@media screen and(max-width:768px) {
	
	.label div{
		font-size: 12px;
		
	}
	}

</style>
